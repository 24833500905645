<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Side Products</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="productId"
        label="productId"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="offerId"
        label="offerId"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="sideProductTypeSelect"
        :items="$store.state.homes.enumTypes.sideProductType"
        item-value="value"
        item-text="id"
        @change="initialize"
        hide-details
        label="Product Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="minSpend"
        label="minSpend"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="maxSpend"
        label="maxSpend"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn
        color="green  "
        dark
        @click="$refs.detail.initialize(null, null)"
        class="mb-2"
        >Create</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="$store.state.sideProducts.productSideProduct"
      :items-per-page="5000"
      class="elevation-1"
    >
      <template v-slot:item.c="{ item }">
        <div>
          {{ item.c | formatMoney }}
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <div>
          {{
            $store.state.homes.enumTypes.sideProductType.find(
              (m) => m.value == item.type
            )?.id
          }}
        </div>
      </template>
      <template v-slot:item.model="{ item }">
        <div>
          {{ item.model }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
    <side-detail-card ref="detail" />
  </div>
</template>
<script>
import SideDetailCard from "./SideDetailCard.vue";

export default {
  components: { SideDetailCard },
  data() {
    return {
      sideProductTypeSelect: { id: "All", value: null },
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Type", value: "type" },
        { text: "ProductId", value: "productId" },
        { text: "OfferId", value: "offerId" },
        { text: "MinSpend", value: "minSpend" },
        { text: "MaxSpend", value: "maxSpend" },
        { text: "MinStake", value: "minStake" },
        { text: "MaxStake", value: "maxStake" },
        { text: "Purchase Count", value: "purchaseCount" },
        { text: "Model", value: "model" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("sideProducts/delete", item.id);
      await this.initialize();
    },
    editItem(item) {
      this.$refs.detail.initialize(item.id, null);
    },
    async initialize() {
      await this.$store.dispatch("sideProducts/getSideProductsQuery", {
        maxSpend: this.maxSpend,
        minSpend: this.minSpend,
        productId: this.productId,
        offerId: this.offerId,

        type: this.sideProductTypeSelect.value,
      });
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
